import { connect } from "react-redux";
import { CLEAR_ALL } from "../../oauth/util/actionTypes";

import { Navigate } from 'react-router-dom';


const Logout = ({ profile,logout }) => {

  logout()
  return (<Navigate to="/login" />)

};  

const mapStateToProps = state => ({
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch({ type: CLEAR_ALL })
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);

