import React from 'react';
import { Outlet } from 'react-router-dom';

import Forbidden from '../auth/Forbidden'

const ProtectedRoute = ({profile, restrict_to_group}) => {

    console.log(profile)

    if (profile.groups.includes(restrict_to_group)) {
        return (<Outlet />)
    } else {
        return(
            <Forbidden />
        )
    }

}

export default ProtectedRoute