import { connect } from "react-redux";
import { SpaceBetween } from "@cloudscape-design/components";

import ReburaAppLayout from "../layout/ReburaAppLayout";
import StackList from "../stacks/StackList";

const Stacks = () => {
	return (
		<ReburaAppLayout page_title=''>
			<SpaceBetween size='l'>
				<StackList title="" />
			</SpaceBetween>
		</ReburaAppLayout>
	);
};

export default connect()(Stacks);
