import React from "react";
import { connect } from "react-redux";
import Spinner from "@cloudscape-design/components/spinner";

import * as oidcApi from "../../oauth/api/oidcApi";
import { generateStateAndNonce } from "../../oauth/actions/tokenActions";

const Login = ({ state, nonce, generateParams }) => {
	if (state === null || nonce === null) {
		generateParams();
	} else {
		oidcApi.beginAuth({ state, nonce });
	}

	return <Spinner />;
};

const mapStateToProps = (state) => ({
	state: state.token.state,
	nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
	generateParams: () => dispatch(generateStateAndNonce()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
