import { useState } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Link,
    Icon,
    SpaceBetween
 } from "@cloudscape-design/components";

import { CodeView } from "@cloudscape-design/code-view";

const StackerExecutionLogsModal = ({ execution, formatDate }) => {

    const [visible, setVisible] = useState(false);

    let logs = ""

    if (execution.logs) {
    	logs = execution.logs.join('\n')
    }

    return (
        <>

         <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={`Logs from: ${formatDate(execution.started_at)}`}
        >

            <SpaceBetween size='s'>
                <CodeView content={logs}/>
            </SpaceBetween>

        </Modal>

        <Link
            onFollow={() => {
                setVisible(true);
            }}
        >
            View Logs <Icon name='search' />
        </Link>
        </>

    );
};


export default connect()(StackerExecutionLogsModal);
