import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  SpaceBetween,
  ColumnLayout,
  Button,
  StatusIndicator,
  Flashbar,
  Tabs,
  ExpandableSection,
  Badge
} from "@cloudscape-design/components";
import { useParams } from "react-router-dom";

import config from "../../config";
import { ValueWithLabel, formatTimestamp } from "../../util";
import StackerConfigDisplay from "./StackerConfigDisplay";
import CloudformationStackList from "./CloudformationStackList";
import StackerExecutionHistory from "./StackerExecutionHistory";

const Customer = ({token, setSplitPanelOpen, setSplitPanel}) => {
  const [customer, setCustomer] = useState();
  const { customer_code } = useParams();
  const [state, setState] = useState({});
  const [flashMessages, setFlashMessages] = useState([]);
  const [runStackerButtonState, setRunStackerButtonState] = useState({
    loading: false,
    text: "Run Stacker",
    disabled: true,
  });
  const pendingMessage = useRef({
    header: "Pending changes to apply",
    type: "warning",
    content:
      "Stacker still has changes to apply. When saving a customer, Stacker does not automatically run. You must press the 'Run Stacker' button",
    dismissible: false,
    id: "stacker_pending",
  });

  useEffect(() => {

    const fetchCustomer = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/customers/${customer_code}`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const json = await response.json();
        setCustomer(json.customer);
        handleStackerStatus(json.customer.stacker_status, json.customer.customer_name);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();
  }, [token, customer_code]);

  const handleStackerStatus = (status, customerName) => {
    switch (status) {
      case "Pending":
        setFlashMessages([pendingMessage.current]);
        setRunStackerButtonState({ loading: false, text: "Run Stacker", disabled: false });
        break;
      case "Failed":
        setFlashMessages([
          {
            header: "Stacker failed to run the last time",
            type: "error",
            content:
              "The most recent attempt to run Stacker failed. View the latest execution history for details.",
            dismissible: false,
            id: "stacker_failed",
          },
        ]);
        setRunStackerButtonState({ loading: false, text: "Run Stacker", disabled: false });
        break;
      case "Queued":
      case "Running":
        setRunStackerButtonState({ loading: true, text: "Running Stacker", disabled: true });
        setFlashMessages([
          {
            header: "Stacker Running",
            type: "info",
            content:
              `Stacker is currently running for ${customerName}. Keep an eye on the Slack channel for progress updates or view the latest execution history for details.`,
            dismissible: true,
            id: "stacker_running",
          },
        ]);
        break;
      default:
        setRunStackerButtonState({ loading: false, text: "Run Stacker", disabled: false });
        break;
    }
  };

  const runStacker = async (e) => {
    e.preventDefault();
    setRunStackerButtonState({ loading: true, text: "Running Stacker", disabled: true });

    try {
      const response = await fetch(`${config.api_endpoint}/stacker/run`, {
        mode: "cors",
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ customers: [customer] }),
      });
      const json = await response.json();
      setFlashMessages([
        {
          header: "Stacker Running",
          type: "info",
          content:
            `Stacker is now running for ${customer.customer_name}.

Execution ID: ${json[customer.customer_code]}`,
          dismissible: false,
          id: "stacker_run",
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusIndicator = (status) => {
    switch (status) {
      case "Pending":
        return "warning";
      case "Queued":
      case "Running":
        return "in-progress";
      case "Completed":
        return "success";
      default:
        return "error";
    }
  };

  const [activeTabId, setActiveTabId] = useState(
    () => localStorage.getItem('activeTabId') || 'stacker_config'
  );

  const handleTabChange = (event) => {
    const newTabId = event.detail.activeTabId;
    setActiveTabId(newTabId);
    localStorage.setItem('activeTabId', newTabId); // Save the active tab ID in localStorage
  };

  return (
    <SpaceBetween size="l">
      <Flashbar items={flashMessages} />
      {customer && (
        <Container header={<Header variant="h1">{customer.customer_name}</Header>}>
          <ExpandableSection header="Customer Details" defaultExpanded={true}>
            <ColumnLayout columns={3} variant="text-grid" minColumnWidth="200">
              <ValueWithLabel label="AWS Account ID">
                {customer.aws_account_id}
              </ValueWithLabel>

              <ValueWithLabel label="Customer Code">
                {customer.customer_code}
              </ValueWithLabel>

              <ValueWithLabel label="Last Modified By">
                {customer.modified_by}
              </ValueWithLabel>

              <ValueWithLabel label="Last Modified On">
                {formatTimestamp(customer.modified_on)}
              </ValueWithLabel>

              <ValueWithLabel label="Stacker Status">
                <StatusIndicator type={getStatusIndicator(customer.stacker_status)}>
                  {customer.stacker_status}
                </StatusIndicator>
              </ValueWithLabel>

              <Button
                onClick={runStacker}
                loading={runStackerButtonState.loading}
                disabled={runStackerButtonState.disabled}
              >
                {runStackerButtonState.text}
              </Button>
            </ColumnLayout>
          </ExpandableSection>

          <Tabs
            activeTabId={activeTabId}
            onChange={handleTabChange}
            tabs={[
              {
                label: "Stacker Configuration",
                id: "stacker_config",
                content: (
                  <StackerConfigDisplay
                    customer={customer}
                    setCustomer={setCustomer}
                    state={state}
                    setState={setState}
                    setFlashMessages={setFlashMessages}
                    pendingMessage={pendingMessage}
                  />
                ),
              },
              {
                label: <>Cloudformation Stacks&nbsp;<Badge color="blue">BETA</Badge></>,
                id: "cloudformation_stacks",
                content: (
                  <CloudformationStackList
                    customer={customer}
                    state={state}
                    setState={setState}
                    setFlashMessages={setFlashMessages}
                    pendingMessage={pendingMessage}
                    setSplitPanelOpen={setSplitPanelOpen}
                    setSplitPanel={setSplitPanel}
                  />
                ),
              },
              {
                label: "Stacker Execution History",
                id: "stacker_history",
                content: (
                  <StackerExecutionHistory
                    customer={customer}
                    state={state}
                    setState={setState}
                  />
                ),
              },
            ]}
          />
        </Container>
      )}
    </SpaceBetween>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});


export default connect(mapStateToProps)(Customer);
