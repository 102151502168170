import queryString from "query-string";

import config from '../../config'

// Static oidc params for a single provider
const authority = config.oidc_auth.authority;
const client_id = config.oidc_auth.client_id;
const redirect_uri = `${window.location.protocol}//${window.location.host}/auth`;
const response_type = "id_token token";
const scope = "openid profile groups params";

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = queryString.stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce
  });
  const authUrl = `${authority}/auth?${params}`;

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
