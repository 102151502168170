
import { Modal, Button, SpaceBetween } from '@cloudscape-design/components';

const ConfirmationPopup = ({ visible, onConfirm, onCancel, title, message }) => {
  return (
    <Modal
      visible={visible}
      header={title}
      onDismiss={onCancel}
      closeAriaLabel="Close modal"
      footer={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onCancel}>Cancel</Button>
          <Button variant="primary" onClick={onConfirm}>Confirm</Button>
        </SpaceBetween>
      }
    >
      {message}
    </Modal>
  );
};

export default ConfirmationPopup