import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Multiselect,
  Input,
  Button,
  SpaceBetween,
  Form,
  Select,
  Container,
  Header,
  Table,
  ExpandableSection
} from "@cloudscape-design/components";

import config from "../../config";

const StackerConfigDisplay = ({ token, customer, setCustomer, state, setState, setFlashMessages, pendingMessage }) => {
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [saveButtonVariant, setSaveButtonVariant] = useState("primary");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    const fetchStacks = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/stacks`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const json = await response.json();

        const parameterValues = {};
        const parameterConfig = {};
        const stacksTmp = [];
        const selectedStacksTmp = [];
        const stacksByName = {};

        json.stacks.forEach((stack) => {
          stack.templates.forEach((template) => {
            parameterConfig[template.name] = {};
            parameterValues[template.name] = {};
            template.parameters.forEach((parameter) => {
              parameterConfig[template.name][parameter.name] = parameter;
            });
          });

          const stackData = {
            name: stack.name,
            label: stack.pretty_name,
            value: stack.name,
            templates: stack.templates,
          };

          stacksByName[stack.name] = stackData;
          stacksTmp.push(stackData);
        });

        customer.stacker_config.stacks.forEach((stack) => {
          parameterValues[stack.name] = {};

          if (stack.variables) {
            Object.entries(stack.variables).forEach(([key, value]) => {
              parameterValues[stack.name][key] = value || parameterConfig[stack.name][key].value;
            });
          }

          const selectedStack = {
            label: stacksByName[stack.parent_stack].label,
            name: stacksByName[stack.parent_stack].name,
            value: stack.parent_stack,
            templates: stacksByName[stack.parent_stack].templates,
          };

          selectedStacksTmp.push(selectedStack);
        });

        setState({
          stacks: stacksTmp,
          selectedStacks: Array.from(new Set(selectedStacksTmp.map((stack) => stack.value))).map((value) =>
            selectedStacksTmp.find((stack) => stack.value === value)
          ),
          parameterConfig,
          parameterValues,
          customer_name: customer.customer_name,
          customer_code: customer.customer_code,
          aws_account_id: customer.aws_account_id,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchStacks();
  }, [token, customer, setState]);

  const enableSaveButton = () => {
    setSaveButtonLoading(false);
    setSaveButtonVariant("primary");
    setSaveButtonDisabled(false);
  };

  const resetSaveButton = () => {
    setSaveButtonLoading(false);
    setSaveButtonVariant("primary");
    setSaveButtonDisabled(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSaveButtonLoading(true);

    state.stacks.forEach((stack) => {
      stack.templates.forEach((template) => {
        template.parameters.forEach((parameter) => {
          parameter.value = state.parameterValues[template.name][parameter.name];
        });
      });
    });

    try {
      const response = await fetch(`${config.api_endpoint}/customers/${customer.customer_code}`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "content-type": "application/json",
          "x-authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(state),
      });
      const json = await response.json();
      setCustomer(json.customer);
      resetSaveButton();
      setFlashMessages([pendingMessage.current]);
    } catch (error) {
      console.error(error);
    }
  };

  const hiddenVars = ["OneLoginApp", "OneLoginSamlProvider", "ParentRole"];

  const getValueOrDefault = (template, parameter) => {
    return (
      state.parameterValues[template.name]?.[parameter.name] ||
      state.parameterConfig[template.name][parameter.name].value
    );
  };

  return (
    <Container header={<Header variant="h2">Stack Configuration</Header>}>
      <SpaceBetween size="m">
        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button loading={saveButtonLoading} variant={saveButtonVariant} disabled={saveButtonDisabled}>
                  Save Changes
                </Button>
              </SpaceBetween>
            }
          >
              <ExpandableSection header="Stacks Selection" defaultExpanded={true}>
                <Multiselect
                  selectedOptions={state.selectedStacks}
                  onChange={({ detail }) => {
                    setState({ ...state, selectedStacks: Array.from(new Set(detail.selectedOptions)) });
                    enableSaveButton();
                  }}
                  options={state.stacks}
                  placeholder="Select stacks to configure"
                />
              </ExpandableSection>

            {state.selectedStacks?.map((stack) =>
              stack.templates.map((template) =>
                template.parameters.some((param) => !hiddenVars.includes(param.name)) ? (
                    <ExpandableSection header={"Parameters for " + template.name + " (" + template.parameters.length + ")"} defaultExpanded={false}>

                    <Table
                      header={<Header variant="h3">Parameters for {template.name}</Header>}
                      columnDefinitions={[
                        {
                          id: "parameter",
                          header: "Parameter Name",
                          cell: (item) => item.name,
                          width: 300,
                        },
                        {
                          id: "value",
                          header: "Value",
                          cell: (item) => (
                            hiddenVars.includes(item.name) ? null : (
                              item.type === "string" ? (
                                <Input
                                  value={getValueOrDefault(template, item)}
                                  onChange={(e) => {
                                    state.parameterValues[template.name][item.name] = e.detail.value;
                                    setState({ ...state, parameterValues: state.parameterValues });
                                    enableSaveButton();
                                  }}
                                  ariaLabel="Parameter input"
                                />
                              ) : (
                                <Select
                                expandToViewport={true}
                                  selectedOption={{
                                    label: state.parameterValues[template.name][item.name],
                                    value: state.parameterValues[template.name][item.name],
                                  }}
                                  onChange={({ detail }) => {
                                    state.parameterValues[template.name][item.name] = detail.selectedOption.value;
                                    setState({ ...state, parameterValues: state.parameterValues });
                                    enableSaveButton();
                                  }}
                                  options={state.parameterConfig[template.name][item.name].valid_values
                                    .split(",")
                                    .map((value) => ({
                                      label: value,
                                      value: value,
                                    }))}
                                  ariaLabel="Parameter select"
                                  dropdownProps={{
                                    dropdownPosition: "top",
                                  }}
                                />
                              )
                            )
                          ),
                          width: 400,
                        },
                      ]}
                      items={template.parameters.filter((param) => !hiddenVars.includes(param.name))}
                    />
                    </ExpandableSection>
                ) : null
              )
            )}
          </Form>
        </form>
      </SpaceBetween>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackerConfigDisplay);
