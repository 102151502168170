import { connect } from "react-redux";

import ReburaAppLayout from "../layout/ReburaAppLayout";
import Customer from "../customers/Customer";

const Customers = () => {

	return (
		<ReburaAppLayout page_title=''>
			<Customer />
		</ReburaAppLayout>
	);
};

export default connect()(Customers);
