import { connect } from "react-redux";
import { useEffect } from "react";

const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (e) {
		return null;
	}
};

const AuthVerify = ({ token, logout }) => {
	useEffect(() => {
		const decodedJwt = parseJwt(token.accessToken);

		if (decodedJwt !== null) {
			if (decodedJwt.exp * 1000 < Date.now()) {
				logout();
			}
		} else {
			logout();
		}
	}, [logout, token.accessToken]);

	return;
};

const mapStateToProps = (state) => ({
	token: state.token,
});

export default connect(mapStateToProps)(AuthVerify);
