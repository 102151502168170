
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';

import config from "../../config";

import ConfirmationPopup from "../common/ConfirmationPopup"

import {
  Box,
  Button,
  Header,
  SpaceBetween,
  ButtonDropdown,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
} from "@cloudscape-design/components";

import CloudformationStackSplitViewContent from "./CloudformationStackSplitViewContent"

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './CloudformationStackListTableConfig';

const CloudformationStackList = ({ token, customer, setSplitPanelOpen, setSplitPanel }) => {
  const [stacks, setStacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({
    pageSize: 10,
    contentDisplay: [
      { id: 'StackId', visible: false },
      { id: 'StackName', visible: true },
      { id: 'StackStatus', visible: true },
      { id: 'CreationTime', visible: true },
      { id: 'LastUpdatedTime', visible: false },
      { id: 'DeletionTime', visible: false },
      { id: 'TemplateDescription', visible: true }
    ],
  });
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  useEffect(() => {
    const fetchStacks = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/customers/${customer.customer_code}/cloudformation/stacks`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const json = await response.json();
        setStacks(JSON.parse(json['stacks']))

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchStacks();

  }, [token, customer]);

  const EmptyState = ({ title, subtitle, action }) => (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    stacks,
    {
      filtering: {
        empty: <EmptyState title="Loading stacks. Please wait" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0], isDescending: true } },
      selection: {},
    }
  );

  const { selectedItems, onSelectionChange } = collectionProps;

  const handleDropdownClick = async (e) => {
    e.preventDefault();

    try {
      switch (e.detail.id) {
        case 'delete':
          setConfirmDeleteModalVisible(true)
          break;
        case 'modify':
          // navigate(`/customers/${selectedItems[0]['customer_code']}/modify`);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDelete = async (e) => {
    // Handle the confirm action here
    console.log('Confirmed!');
    setConfirmDeleteModalVisible(false);

    await fetch(`${config.api_endpoint}/customers/${customer.customer_code}/cloudformation/stacks/${encodeURIComponent(selectedItems[0]['StackName'])}`, {
        mode: "cors",
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ customers: selectedItems }),
      });


  };

  const handleCancelDelete = () => {
    setConfirmDeleteModalVisible(false);
  };

  const setSelectedStack = (e) => {
    const selectedStack = e['detail']['selectedItems'][0]
    setSplitPanel(<CloudformationStackSplitViewContent selectedStack={selectedStack} setSplitPanelOpen={setSplitPanelOpen} />)
    setSplitPanelOpen(true)
    onSelectionChange(e)
  }


  return (
    <>
      <Table
        {...collectionProps}
        selectionType="single"
        header={
          <Header
            counter={`(${stacks.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={handleDropdownClick}
                  items={[
                    {
                      text: "Modify",
                      id: "modify",
                      disabled: true,
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      disabled: selectedItems.length !== 1,
                    },
                    // {
                    //   text: "Run Stacker",
                    //   id: "run",
                    //   disabled: selectedItems.length === 0,
                    // },
                  ]}
                >
                  Actions
                </ButtonDropdown>

                <ConfirmationPopup
                  visible={isConfirmDeleteModalVisible}
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  title="Confirm Delete Stack?"
                  message="Are you sure you want to delete this stack? This cannot be undone!"
                />
              </SpaceBetween>
            }
          >
            Cloudformation Stacks
          </Header>
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading stacks"
        onSelectionChange={setSelectedStack}
        // trackBy="StackId"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter stacks"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />

    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CloudformationStackList);
