import { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
    Button,
    Box,
    CollectionPreferences,
    Pagination,
    Table,
    TextFilter,
 } from "@cloudscape-design/components";


import config from "../../config";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './StackerExecutionHistoryTableConfig';

const StackerExecutionHistory = ({ token, customer, state, setState }) => {

    const [executions, setExecutions] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        fetch(`${config.api_endpoint}/customers/${customer.customer_code}/stacker-executions`, {
            mode: "cors",
            method: "GET",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            setExecutions(json["executions"])
            setLoading(false)
        })
        .catch((error) => console.error(error));
    }, [token, customer]);




    const EmptyState = ({ title, subtitle, action }) => {
      return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
      );
    }

    const [preferences, setPreferences] = useState({
        pageSize: 20,
        contentDisplay: [
          { id: 'execution_id', visible: true },
          { id: 'status', visible: true },
          { id: 'executed_by', visible: true },
          { id: 'started_at', visible: true },
          { id: 'ended_at', visible: true },
          { id: 'ecs_task_id', visible: true },
          { id: 'logs_link', visible: true },
        ],
    });

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        executions,
        {
          filtering: {
            empty: <EmptyState title="Loading customers. Please wait" />,
            noMatch: (
              <EmptyState
                title="No matches"
                action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
              />
            ),
          },
          pagination: { pageSize: preferences.pageSize },
          sorting:  { defaultState: { sortingColumn: columnDefinitions[3], isDescending: true } },
          selection: {},
        }
    );

    return (
        <>

            <Table
              {...collectionProps}
              // selectionType="multi"

              resizableColumns
              columnDefinitions={columnDefinitions}
              columnDisplay={preferences.contentDisplay}
              items={items}
              loading={loading}
              loadingText="Loading execution history"
              pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
              filter={
                <TextFilter
                  {...filterProps}
                  countText={getMatchesCountText(filteredItemsCount)}
                  filteringAriaLabel="Filter executions"
                />
              }
              preferences={
                <CollectionPreferences
                  {...collectionPreferencesProps}
                  preferences={preferences}
                  onConfirm={({ detail }) => setPreferences(detail)}
                />
              }
            >

            </Table>

        </>

    );
};


const mapStateToProps = (state) => ({
    token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackerExecutionHistory);