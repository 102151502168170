import { RECEIVE_PROFILE_DATA, CLEAR_ALL } from "../util/actionTypes";

const profileReducer = (state = null, { type, payload }) => {

  switch (type) {
    case RECEIVE_PROFILE_DATA:
      return {
        email: payload.profile.email,
        fullname: payload.profile.name,
        groups: payload.profile.groups,
        firstname: payload.profile.given_name,
        lastname: payload.profile.family_name,
        is_admin: payload.profile.params.is_admin

      };
    case CLEAR_ALL:
      return null;
    default:
      return state;
  }
};

export default profileReducer;
