import React from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { jwtDecode } from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import { Spinner } from "@cloudscape-design/components";

import {
	receiveAccessToken,
	receiveIdToken,
	receiveExpiresIn,
} from "../../oauth/actions/tokenActions";
import { receiveProfileData } from "../../oauth/actions/profileActions";

const Auth = ({ location, profile, receiveProfile, receiveTokens }) => {
	const { hash } = useLocation();
	if (isEmpty(profile)) {
		const response = queryString.parse(hash);
		if (response.error) {
			// alert(response.error_description);

			return (
				<Navigate
					to={"/login-error?error=" + response.error_description}
				/>
			);
		} else {
			receiveTokens(
				response.access_token,
				response.idToken,
				response.expires_in
			);
			receiveProfile(jwtDecode(response.id_token));
		}
		// Render loader
		return <Spinner />;
	} else {
		return <Navigate to='/' />;
	}
};

const mapStateToProps = (state) => ({
	profile: state.profile,
	state: state.token.state,
	nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
	receiveProfile: (data) => dispatch(receiveProfileData(data)),
	receiveTokens: (accessToken, idToken, expires_in) => {
		dispatch(receiveAccessToken(accessToken));
		dispatch(receiveIdToken(idToken));
		dispatch(receiveExpiresIn(expires_in));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
