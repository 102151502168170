import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';
import config from "../../config";
import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Icon,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
  Flashbar,
  Alert,
  Link
} from "@cloudscape-design/components";
import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './TemplatesListTableConfig';
import AddTemplateModal from "./AddTemplateModal";

const TemplatesList = ({ token, title }) => {
  const [templates, setTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [flashMessages, setFlashMessages] = useState([]);

  const EmptyState = ({ title, subtitle, action }) => (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'name', visible: true },
      { id: 'template_path', visible: true },
      { id: 'region', visible: true },
      { id: 'notification_arns', visible: true },
      { id: 'parameters', visible: true },
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    templates,
    {
      filtering: {
        empty: <EmptyState title="No templates" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0], isDescending: false } },
      selection: {},
    }
  );

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/templates`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setTemplates(data.templates);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTemplates();
  }, [token]);

  return (
    <SpaceBetween size="xs">
      <AddTemplateModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        templates={templates}
        setTemplates={setTemplates}
        setParentFlashMessages={setFlashMessages}
        token={token}
      />

      <Flashbar items={flashMessages} />

      <Alert statusIconAriaLabel="Info" header="What are Templates?">
        Templates are an interface between real CloudFormation templates from the rebura-cfn-templates repository. You must define templates here before you can deploy them.
        <br />
        <br />
        When creating a template, you can declare any Parameters you have in the CloudFormation template, as well as setting default values for them. Don't worry, default values can always be overridden.
        <br />
        <br />
        Once you've created your template, create a <Link external href="/stacks">Stack</Link> that includes it.
      </Alert>

      <Table
        {...collectionProps}
        selectionType={false}
        header={
          <Header
            counter={`(${items.length}/${templates.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setIsModalVisible(true)}>
                  Register New Template <Icon name='add-plus' />
                </Button>
              </SpaceBetween>
            }
          >
            Templates
          </Header>
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={isLoading}
        loadingText="Loading templates"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter templates"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
    </SpaceBetween>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(TemplatesList);
