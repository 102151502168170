let config = {}

if (window.configFromFile) {
    console.log(`Running in prod mode. Installation ID: ${window.configFromFile.installation_id}`)
    config = {
        api_endpoint:
            window.configFromFile.api_endpoint,
    
        oidc_auth: {
            authority:
                window.configFromFile.oidc_auth.authority,
            client_id:
                window.configFromFile.oidc_auth.client_id
        },
    }
} else {
    console.log('Prod config not found. Looking for environment variables')
    config = {
        api_endpoint:
            process.env.REACT_APP_API_ENDPOINT,
    
        oidc_auth: {
            authority:
                process.env.REACT_APP_OIDC_AUTHORITY,
            client_id: 
                process.env.REACT_APP_OIDC_CLIENT_ID
        },
    };
}



export default config