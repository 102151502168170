import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './components/Root';

import { Theme, applyTheme } from '@cloudscape-design/components/theming';

import reburaTheme from './reburaTheme'

const theme: Theme = reburaTheme;

applyTheme({ theme });

ReactDOM.render(<Root />, document.getElementById('root'));