import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCollection } from '@cloudscape-design/collection-hooks';

import config from "../../config";

import {
  Box,
  Button,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Icon,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './CustomerListTableConfig';

const CustomersList = ({ token, title }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'customer_code', visible: true },
      { id: 'customer_name', visible: true },
      { id: 'aws_account_id', visible: true },
      { id: 'modified_on', visible: true },
      { id: 'stacker_status', visible: true },
      { id: 'latest_stacker_run', visible: true },
      { id: 'keycloak_access', visible: true },
    ],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/customers`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const json = await response.json();
        setCustomers(json["customers"]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomers();
  }, [token]);

  const EmptyState = ({ title, subtitle, action }) => (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    customers,
    {
      filtering: {
        empty: <EmptyState title="Loading customers. Please wait" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[3], isDescending: true } },
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

  const handleDropdownClick = async (e) => {
    e.preventDefault();

    try {
      switch (e.detail.id) {
        case 'run':
          await fetch(`${config.api_endpoint}/stacker/run`, {
            mode: "cors",
            method: "POST",
            headers: {
              "content-type": "application/json",
              "x-authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ customers: selectedItems }),
          });
          break;
        case 'modify':
          navigate(`/customers/${selectedItems[0]['customer_code']}/modify`);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        selectionType="multi"
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${customers.length})`
                : `(${customers.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={handleDropdownClick}
                  items={[
                    {
                      text: "Modify",
                      id: "modify",
                      disabled: selectedItems.length !== 1,
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      disabled: selectedItems.length !== 1,
                    },
                    {
                      text: "Run Stacker",
                      id: "run",
                      disabled: selectedItems.length === 0,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>

                <Button
                  variant="primary"
                  onClick={() => navigate("/customers/add")}
                >
                  Add new customer <Icon name='add-plus' />
                </Button>
              </SpaceBetween>
            }
          >
            Customers
          </Header>
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading customers"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter customers"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomersList);
