import {
  Box,
  Header,
  SplitPanel
} from "@cloudscape-design/components";

const CloudformationStackSplitViewContent = ({ selectedStack, setSplitPanelOpen }) => {


    return (<SplitPanel
        header={<Header>Stack Details: {selectedStack.StackName}</Header>}
        hidePreferencesButton={true}
        onDismiss={() => setSplitPanelOpen(false)}
      >
        <Box padding={{ vertical: 'm', horizontal: 'l' }}>
          <Header variant="h3">Parameters</Header>
          {selectedStack.Parameters && selectedStack.Parameters.length > 0 ? (
            <ul>
              {selectedStack.Parameters.map((parameter, index) => (
                <li key={index}>
                  <strong>{parameter.ParameterKey}:</strong> {parameter.ParameterValue}
                </li>
              ))}
            </ul>
          ) : (
            <Box>No parameters available</Box>
          )}
        </Box>
      </SplitPanel>)

}

export default (CloudformationStackSplitViewContent)