import { Provider } from "react-redux";
import { createStore } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "../oauth/reducers/_rootReducer";
import storage from "redux-persist/lib/storage";

import Router from "./routing/Router";

const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

const Root = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router />
		</PersistGate>
	</Provider>
);

export default Root;
