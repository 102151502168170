import { useState } from "react";
import {
  Modal,
  SpaceBetween,
  Button,
  Form,
  FormField,
  Input,
  Container,
  Icon,
  Flashbar,
  Header,
  Select,
  Box,
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const AddTemplateModal = ({ token, visible, setVisible, templates, setTemplates, setParentFlashMessages }) => {
  const [formState, setFormState] = useState({});
  const [parameters, setParameters] = useState([]);
  const [flashMessages, setFlashMessages] = useState([]);

  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${config.api_endpoint}/templates`, {
      mode: "cors",
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...formState,
        parameters: parameters,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setTemplates(json["templates"]);
        setVisible(false);
        setFormState({});
        setParentFlashMessages([
          {
            header: "Successfully created a new template",
            type: "success",
            content: "Your template has been added. Don't forget to add it to a stack",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setParentFlashMessages([]),
            id: "new_lease",
          },
        ]);
      })
      .catch((error) => {
        console.error(error);
        setFlashMessages([
          {
            header: "Error while creating a new template",
            type: "error",
            content: error.message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setFlashMessages([]),
            id: "new_lease",
          },
        ]);
      });
  };

  const removeParameter = (index) => {
    setParameters((prev) => prev.filter((_, i) => i !== index));
  };

  const addParameter = () => {
    const newParameter = {
      type: formState["newParameterType"],
      name: formState["newParameterName"],
      value: formState["newParameterType"] === "boolean" ? "true,false" : formState["newParameterValue"],
    };

    setParameters((prev) => [...prev, newParameter]);
    setFormState((prev) => ({
      ...prev,
      newParameterType: "",
      newParameterName: "",
      newParameterValue: "",
    }));
  };

  return (
    <Modal onDismiss={() => setVisible(false)} visible={visible} header="Register New Template">
      <SpaceBetween size="xs">
        <Flashbar items={flashMessages} />

        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
                <Button variant="primary">Add Template</Button>
              </SpaceBetween>
            }
          >
            <Container>
              <SpaceBetween direction="vertical" size="s">
                <FormField label="Name">
                  <Input
                    value={formState["name"] || ""}
                    onChange={(e) => handleInputChange("name", e.detail.value)}
                    placeholder="name me"
                  />
                </FormField>

                <FormField label="Template Path" constraintText="Path in the rebura-cfn-templates repository for this template, with no preceding slash">
                  <Input
                    value={formState["template_path"] || ""}
                    onChange={(e) => handleInputChange("template_path", e.detail.value)}
                    placeholder="templates/SubDirector/my-template.yaml"
                  />
                </FormField>

                <FormField label="Region (optional)" constraintText="Set only if stack must be deployed to a specific region">
                  <Input
                    value={formState["region"] || ""}
                    onChange={(e) => handleInputChange("region", e.detail.value)}
                    placeholder="us-east-1"
                  />
                </FormField>

                <FormField label="Notification ARNs (optional)" constraintText="SNS Topic ARNs, comma-delimited.">
                  <Input
                    value={formState["notification_arns"] || ""}
                    onChange={(e) => handleInputChange("notification_arns", e.detail.value)}
                    placeholder="arn:aws:sns:eu-west-1:123412341234:catch-my-cloudformation-events"
                  />
                </FormField>

                <hr />

                <Header variant="h3">Template Parameters</Header>
                <Header variant="h4">Add new parameter</Header>

                <FormField label="Parameter Name" constraintText="Must match the parameter name in the CloudFormation Template">
                  <Input
                    value={formState["newParameterName"] || ""}
                    onChange={(e) => handleInputChange("newParameterName", e.detail.value)}
                    placeholder="Parameter Name"
                  />
                </FormField>

                <FormField label="Parameter Type">
                  <Select
                    selectedOption={{ value: formState["newParameterType"] || "" }}
                    onChange={(e) => handleInputChange("newParameterType", e.detail.selectedOption.value)}
                    options={[
                      { value: "string", label: "string" },
                      { value: "boolean", label: "boolean" },
                      { value: "enum", label: "enum" },
                    ]}
                  />
                </FormField>

                <FormField label="Parameter Value" constraintText="For string, enter a default value if required. For enum, enter all allowed values, comma-delimited">
                  <Input
                    disabled={formState["newParameterType"] === "boolean"}
                    value={formState["newParameterValue"] || ""}
                    onChange={(e) => handleInputChange("newParameterValue", e.detail.value)}
                    placeholder=""
                  />
                </FormField>

                <Button onClick={(e) => {
                  e.preventDefault();
                  addParameter();
                }}>
                  Add this parameter <Icon name="add-plus" />
                </Button>

                <hr />

                {parameters.map((parameter, index) => (
                  <Box key={index}>
                    <FormField label={parameter["name"]}>
                      Type: {parameter["type"]}
                      <br />
                      Value: {parameter["value"]}
                      <br />
                      <Button
                        size="s"
                        onClick={(e) => {
                          e.preventDefault();
                          removeParameter(index);
                        }}
                      >
                        <Icon name="remove" /> remove
                      </Button>
                    </FormField>
                    <hr />
                  </Box>
                ))}
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      </SpaceBetween>
    </Modal>
  );
};

export default connect()(AddTemplateModal);
