import { connect } from "react-redux";
import React, { useState } from "react";
import {
	SideNavigation,
	AppLayout,
	TopNavigation,
} from "@cloudscape-design/components";

import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";

const LOCALE = "en";

const ReburaAppLayout = (props) => {
	const [navOpen, setNavOpen] = useState([]);

	props = {
		...{
			show_breadcrumbs: true,
			nav_open: true,
			show_side_nav: true,
			page_title: "Rebura Stacker Portal",
		},
		...props,
	};

	let navItems = [
		{ type: "link", text: `Customers`, href: `/customers` },
		{ type: "link", text: `Stacks`, href: `/stacks` },
		{ type: "link", text: `Templates`, href: `/templates` },
	]

	let util_list = [];

	if (props.profile !== null) {
		util_list.push({
			type: "menu-dropdown",
			text: props.profile.fullname,
			description: props.profile.email,
			iconName: "user-profile",
			items: [{ type: "link", text: "Sign out", href: "/logout" }],
		});



		// if (props.profile.is_admin === "Admin") {
		// navItems.push({
		// 	type: "link",
		// 	text: `Customers`,
		// 	href: `/customers`,
		// })
		// navItems.push(
		// {
		// 	type: "link",
		// 	text: `Settings`,
		// 	href: `/settings`,
		// })
		// }
	}

	const navToggle = (e) => {
		setNavOpen(!navOpen);
	};

	const [splitPanelOpen, setSplitPanelOpen] = useState(true);
	const [splitPanel, setSplitPanel] = useState(null);

	return (
		<I18nProvider locale={LOCALE} messages={[messages]}>
			<TopNavigation
				identity={{
					href: "#",
					title: "Rebura Stacker Portal",
				}}
				utilities={util_list}
			/>

			<AppLayout
				title={props.page_title}
				toolsHide={true}
				onNavigationChange={navToggle}
				maxContentWidth={Number.MAX_VALUE}
				navigationOpen={navOpen}
				splitPanelOpen={splitPanelOpen}
				// splitPanelSize={400}
				onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
				splitPanel={splitPanel}
				navigation={
					props.show_side_nav ? (
						<SideNavigation
							header={{
								href: "#",
								text: "Navigation",
							}}
							items={navItems}
						/>
					) : null
				}
				content={React.Children.map(props.children, child => {
				        	return React.cloneElement(child, { setSplitPanelOpen, setSplitPanel })

						}
				      )}
			/>
		</I18nProvider>
	);
};

const mapStateToProps = (state) => ({
	profile: state.profile
});

export default connect(mapStateToProps)(ReburaAppLayout);
