import * as React from 'react';

import StackerExecutionLogsModal from "./StackerExecutionLogsModal";
import { getStackerStatus } from "./CustomerListTableConfig"

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatDate(date) {

  if (!date) {
    return ""
  }

  const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  return `${dateFormatter.format(date * 1000)}, ${timeFormatter.format(date * 1000)}`;
}



export const columnDefinitions = [
  {
    id: 'execution_id',
    header: 'Execution ID',
    cell: item => item.execution_id,
    sortingField: 'execution_id',
    isRowHeader: true,
  },
  {
    id: 'status',
    header: 'Status',
    width: 130,
    min_width:130,
    cell: item => getStackerStatus(item.status),
    sortingField: 'status',
  },
  {
    id: 'executed_by',
    header: 'Started By',
    cell: item => item.executed_by
  },
  {
    id: 'started_at',
    header: 'Started',
    cell: item => formatDate(item.started_at),
    sortingComparator: (a, b) => a.started_at.valueOf() - b.started_at.valueOf(),
  },
  {
    id: 'ended_at',
    header: 'Ended',
    cell: item => formatDate(item.ended_at),
  },
  {
    id: 'ecs_task_id',
    header: 'ECS Task ID',
    cell: item => item.ecs_task_id
  },
  {
    id: 'logs_link',
    header: 'Logs',
    cell: item => <StackerExecutionLogsModal execution={item} formatDate={formatDate}/>
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 items' },
    { value: 20, label: '20 items' },
    { value: 50, label: '50 items' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};