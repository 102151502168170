import { useState, useEffect } from "react";
import {
  Modal,
  SpaceBetween,
  Button,
  Form,
  FormField,
  Input,
  Container,
  Multiselect,
  Flashbar,
  Select
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const AddStackModal = ({ token, visible, setVisible, stacks, setStacks, setParentFlashMessages }) => {
  const [formState, setFormState] = useState({});
  const [parameters, setParameters] = useState({});
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [flashMessages, setFlashMessages] = useState([]);

  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${config.api_endpoint}/stacks`, {
      mode: "cors",
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(formState),
    })
      .then((response) => response.json())
      .then((json) => {
        setStacks(json["stacks"]);
        setVisible(false);
        setFormState({});
        setParentFlashMessages([
          {
            header: "Successfully created a new stack",
            type: "success",
            content: "Your stack has been added. It can now be selected when creating or updating customers",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setParentFlashMessages([]),
            id: "new_stack",
          },
        ]);
      })
      .catch((error) => {
        console.error(error);
        setFlashMessages([
          {
            header: "Error while creating a new stack",
            type: "error",
            content: error.message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setFlashMessages([]),
            id: "new_stack_error",
          },
        ]);
      });
  };

  useEffect(() => {
    fetch(`${config.api_endpoint}/templates`, {
      mode: "cors",
      method: "GET",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const templatesData = json["templates"].map((template) => {
          template.parameters.forEach((parameter) => {
            setParameters((prev) => ({
              ...prev,
              [parameter.name]: parameter,
            }));
          });

          return {
            name: template["name"],
            label: template["name"],
            value: template["name"],
            description: template["template_path"],
            parameters: template["parameters"],
          };
        });
        setTemplates(templatesData);
      })
      .catch((error) => console.error(error));
  }, [token]);

  return (
    <Modal onDismiss={() => setVisible(false)} visible={visible} header="Create New Stack">
      <SpaceBetween size="xs">
        <Flashbar items={flashMessages} />

        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
                <Button variant="primary">Submit</Button>
              </SpaceBetween>
            }
          >
            <Container>
              <SpaceBetween direction="vertical" size="s">
                <FormField label="Name">
                  <Input
                    value={formState["name"] || ""}
                    onChange={(e) => handleInputChange("name", e.detail.value)}
                    placeholder="name me"
                  />
                </FormField>

                <Multiselect
                  selectedOptions={selectedTemplates}
                  onChange={({ detail }) => {
                    setSelectedTemplates(detail.selectedOptions);
                    handleInputChange("templates", detail.selectedOptions);
                  }}
                  options={templates}
                  placeholder="Choose templates"
                />

                {selectedTemplates.map((template, key) => (
                  <Container key={key} header={`Parameters for template: ${template.name}`}>
                    {template.parameters.map((parameter) => (
                      <FormField key={parameter.name} label={parameter.name}>
                        {parameter.type === "string" && (
                          <Input
                            value={parameters[parameter.name]?.value || ""}
                            onChange={(e) => {
                              setParameters((prev) => ({
                                ...prev,
                                [parameter.name]: {
                                  ...prev[parameter.name],
                                  value: e.detail.value,
                                },
                              }));
                              handleInputChange("parameters", parameters);
                            }}
                          />
                        )}

                        {(parameter.type === "enum" || parameter.type === "boolean") && (
                          <Select
                            selectedOption={parameters[parameter.name]?.value || ""}
                            onChange={({ detail }) => {
                              setParameters((prev) => ({
                                ...prev,
                                [parameter.name]: {
                                  ...prev[parameter.name],
                                  value: detail.selectedOption,
                                },
                              }));
                              handleInputChange("parameters", parameters);
                            }}
                            options={parameter.valid_values.split(",").map((value) => ({
                              label: value,
                              value: value,
                            }))}
                          />
                        )}
                      </FormField>
                    ))}
                  </Container>
                ))}
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      </SpaceBetween>
    </Modal>
  );
};

export default connect()(AddStackModal);
