import * as React from 'react';
import {
  StatusIndicator
} from '@cloudscape-design/components';

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export function getStackStatus(status) {

  let indicator_type = ""

  switch (status) {

    case "DELETE_COMPLETE":
    case "CREATE_COMPLETE":
    case "UPDATE_COMPLETE":
      indicator_type = "success"
      break

    case 'UPDATE_ROLLBACK_COMPLETE':
    case 'ROLLBACK_COMPLETE':
      indicator_type = 'warning'
      break

    case 'CREATE_FAILED':
    case 'DELETE_FAILED':
    case 'ROLLBACK_FAILED':
    case 'UPDATE_ROLLBACK_FAILED':
      indicator_type = "error"
      break

    case 'CREATE_IN_PROGRESS':
    case 'DELETE_IN_PROGRESS':
    case 'REVIEW_IN_PROGRESS':
    case 'ROLLBACK_IN_PROGRESS':
    case 'UPDATE_IN_PROGRESS':
    case 'UPDATE_ROLLBACK_IN_PROGRESS':
    case 'UPDATE_COMPLETE_CLEANUP_IN_PROGRESS':
    case 'UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS':
      indicator_type = "info"
      break

    default:
      indicator_type = "pending"
  }

  return (
    <>
        <StatusIndicator type={indicator_type}>
          {status}
        </StatusIndicator>
    </>
  )
}

export const columnDefinitions = [
  {
    id: 'StackId',
    header: 'StackId',
    cell: item => item.StackId,
    ariaLabel: createLabelFunction('StackId'),
    sortingField: 'StackId',
    isRowHeader: true,
  },
  {
    id: 'StackStatus',
    header: 'Stack Status',
    cell: item => getStackStatus(item.StackStatus),
    ariaLabel: createLabelFunction('Stack Status'),
    sortingField: 'StackStatus',
  },
  {
    id: 'StackName',
    header: 'Stack Name',
    cell: item => item.StackName,
    ariaLabel: createLabelFunction('Stack Name'),
    sortingField: 'StackName',
  },
  {
    id: 'CreationTime',
    header: 'Created Time',
    cell: item => item.CreationTime,
    ariaLabel: createLabelFunction('Created Time'),
    sortingComparator: (a, b) => a.CreationTime.valueOf() - b.CreationTime.valueOf(),
  },
  {
    id: 'LastUpdatedTime',
    header: 'Updated Time',
    cell: item => item.LastUpdatedTime || "-",
    ariaLabel: createLabelFunction('Updated Time'),
    sortingComparator: (a, b) => a.LastUpdatedTime.valueOf() - b.LastUpdatedTime.valueOf(),
  },
  {
    id: 'DeletionTime',
    header: 'Deleted Time',
    cell: item => item.DeletionTime || "-",
    ariaLabel: createLabelFunction('Deleted Time'),
    sortingComparator: (a, b) => a.DeletionTime.valueOf() - b.DeletionTime.valueOf(),
  },
  {
    id: 'TemplateDescription',
    header: 'Description',
    cell: item => item.TemplateDescription,
    ariaLabel: createLabelFunction('Description')
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 stacks' },
    { value: 20, label: '20 stacks' },
    { value: 50, label: '50 stacks' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'StackName' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};