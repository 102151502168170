import { Box } from "@cloudscape-design/components";

export const getStackerStatusIndicator = (status) => {
        switch (status) {
            case "Available":
                return "success";
            case "Leased":
                return "in-progress";
            case "Deleting":
            case "Cleaning In Progress":
                return "loading";
            case "Dirty":
                return "warning";
            case "Failed":
                return "error";
            case "Pending":
            default:
                return "info";
        }
    };

export const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant='awsui-key-label'>{label}</Box>
        <div>{children}</div>
    </div>
);

export const formatTimestamp = (ts) => {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(Number(ts) * 1000);
  };