import * as React from 'react';
import {
  Link
 } from '@cloudscape-design/components';

import ParametersList from "./ParametersList"

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const columnDefinitions = [
  {
    id: 'name',
    header: 'Template Name',
    cell: item => item.name,
    sortingField: 'name',
    isRowHeader: true,
  },
  {
    id: 'template_path',
    header: 'Template Path',
    cell: item => <Link external href={"https://github.com/ReburaIT/rebura-cfn-templates/blob/master/" + item.template_path.replace('templates/', '')} variant="primary">{item.template_path}</Link>,
    sortingField: 'template_path',
  },
  {
    id: 'region',
    header: 'Region',
    cell: item => item.region || "",
    sortingField: 'region',
  },
  {
    id: 'parameters',
    header: 'Parameters',
    cell: item => <ParametersList parameters={item.parameters} template_name={item.name}/>,
    sortingField: 'parameters',
  },

];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 templates' },
    { value: 20, label: '20 templates' },
    { value: 50, label: '50 templates' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};