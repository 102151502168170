import * as React from 'react';

import StackTemplatesList from "./StackTemplatesList"

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const columnDefinitions = [
  {
    id: 'name',
    header: 'Stack Name',
    cell: item => item.name,
    sortingField: 'name',
    isRowHeader: true,
  },

  {
    id: 'templates',
    header: 'Templates',
    cell: item => <StackTemplatesList templates={item.templates} stack_name={item.name}/>,
    sortingField: 'templates',
  },

];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 stacks' },
    { value: 20, label: '20 stacks' },
    { value: 50, label: '50 stacks' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};