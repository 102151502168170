import { useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Link,
  Icon,
  SpaceBetween,
  TextContent
} from "@cloudscape-design/components";

const StackTemplatesList = ({ templates, stack_name }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        onDismiss={() => setIsModalVisible(false)}
        visible={isModalVisible}
        header={`Templates for ${stack_name}`}
      >
        <SpaceBetween size="s">
          <TextContent>
            <p>This stack deploys the following templates:</p>
            <ul>
              {templates.map((template, index) => (
                <li key={index}>{template.name}</li>
              ))}
            </ul>
          </TextContent>
        </SpaceBetween>
      </Modal>

      <Link onFollow={() => setIsModalVisible(true)}>
        View Templates ({templates.length}) <Icon name="search" />
      </Link>
    </>
  );
};

export default connect()(StackTemplatesList);
