import { useState, useEffect } from "react";
import {
  SpaceBetween,
  Button,
  Form,
  FormField,
  Input,
  Container,
  Multiselect,
  Alert,
  Link,
  Box,
  Select,
  Header,
  ColumnLayout
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const CustomerAdd = ({ token }) => {
  const [state, setState] = useState({
    stacks: [],
    selectedStacks: [],
    parameters: {},
    customer_name: "",
    customer_code: "",
    aws_account_id: ""
  });
  const navigate = useNavigate();

  const handleInputChange = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = [];

    if (!state.customer_name) {
      validationErrors.push("Customer Name is required.");
    }
    if (!state.customer_code) {
      validationErrors.push("Customer Code is required.");
    }
    if (!state.aws_account_id || !/^[0-9]{12}$/.test(state.aws_account_id)) {
      validationErrors.push("AWS Account ID must be a 12-digit number.");
    }

    if (validationErrors.length > 0) {
      alert(validationErrors.join("\n\n"));
      return;
    }

    state.selectedStacks.forEach((stack, stackKey) => {
      stack.templates.forEach((template, templateKey) => {
        template.parameters.forEach((parameter, parameterKey) => {
          const paramValue = state.parameters[template.name][parameter.name].value;
          state.selectedStacks[stackKey].templates[templateKey].parameters[parameterKey].value =
            parameter.type !== "string" ? paramValue.value : paramValue;
        });
      });
    });

    fetch(`${config.api_endpoint}/customers`, {
      mode: "cors",
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`
      },
      body: JSON.stringify(state),
    })
      .then((response) => response.json())
      .then((json) => {
        navigate(`/customers/${json.customer.customer_code}/view`);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const fetchStacks = async () => {
      try {
        const response = await fetch(`${config.api_endpoint}/stacks`, {
          mode: "cors",
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        });
        const json = await response.json();

        let parametersTmp = {};
        let stacksTmp = [];
        let stacksByName = {};

        json.stacks.forEach((stack) => {
          stack.templates.forEach((template) => {
            parametersTmp[template.name] = {};
            template.parameters.forEach((parameter) => {
              if (parameter.type !== "string") {
                parameter.value = {
                  label: parameter.value,
                  value: parameter.value
                };
              }
              parametersTmp[template.name][parameter.name] = parameter;
            });
          });

          const stackData = {
            name: stack.name,
            label: stack.pretty_name,
            value: stack.name,
            templates: stack.templates
          };

          stacksByName[stack.name] = stackData;
          stacksTmp.push(stackData);
        });

        setState((prev) => ({
          ...prev,
          stacks: stacksTmp,
          parameters: parametersTmp,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchStacks();
  }, [token]);

  const templateUrl = "https://download-rebura-bucket.s3.eu-west-1.amazonaws.com/stacker-execution-role-template.yaml";

  const hiddenVars = ["OneLoginApp", "OneLoginSamlProvider", "ParentRole"];

  return (
    <Container header={<Header variant="h1">Add New Customer</Header>}>
      <SpaceBetween size="l">
        <Alert statusIconAriaLabel="Info" header="Have you deployed the role yet?">
          Before onboarding a customer to the Stacker pipeline, please ensure the stacker deployment role has been deployed in the target AWS account.
          <br />
          <br />
          Stacker is hard-coded to use the IAM role `rebura-deploy-StackerExecutionRole` and is included in the following CloudFormation template. The stack requires no parameters to be set so it is a simple deployment.
          <br />
          <br />
          <Link external href={templateUrl}>{templateUrl}</Link>
        </Alert>

        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button variant="primary">Submit</Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <ColumnLayout columns={2} variant="text-grid">
                <FormField label="Customer Name" description="The full name of the customer.">
                  <Input
                    placeholder="Enter customer name"
                    value={state.customer_name}
                    onChange={(e) => handleInputChange("customer_name", e.detail.value)}
                  />
                </FormField>

                <FormField label="Customer Code" description="A unique code to identify the customer.">
                  <Input
                    placeholder="Enter customer code"
                    value={state.customer_code}
                    onChange={(e) => handleInputChange("customer_code", e.detail.value)}
                  />
                </FormField>
              </ColumnLayout>

              <FormField label="AWS Account ID" description="The AWS Account ID for the customer.">
                <Input
                  placeholder="Enter AWS Account ID"
                  value={state.aws_account_id}
                  onChange={(e) => handleInputChange("aws_account_id", e.detail.value)}
                />
              </FormField>

              <FormField label="Stacks to Deploy" description="Select the stacks that you want to deploy for the customer.">
                <Multiselect
                  selectedOptions={state.selectedStacks}
                  onChange={({ detail }) => handleInputChange("selectedStacks", detail.selectedOptions)}
                  options={state.stacks}
                  placeholder="Choose stacks"
                />
              </FormField>

              {state.selectedStacks.map((stack, stackKey) => (
                stack.templates.map((template, templateKey) => (
                    template.parameters.filter((param) => !hiddenVars.includes(param.name)).length > 0 && (
                      <>
                  <Box key={`template-${stackKey}-${templateKey}`} margin={{ vertical: "m" }}>
                        <Box margin={{ bottom: "s" }}>
                          <b>Parameters for template: {template.name}</b>
                        </Box>
                        <ColumnLayout columns={2} variant="text-grid">
                          {template.parameters.map((parameter, paramKey) => {
                            if (hiddenVars.includes(parameter.name)) {
                              return null;
                            }

                            return (
                              <FormField
                                label={parameter.name}
                                key={`param-${stackKey}-${templateKey}-${paramKey}`}
                                description={parameter.description || "Enter the value for this parameter."}
                              >
                                {parameter.type === "string" && (
                                  <Input
                                    placeholder={`Enter value for ${parameter.name}`}
                                    value={state.parameters[template.name][parameter.name].value}
                                    onChange={(e) => {
                                      const updatedParameters = { ...state.parameters };
                                      updatedParameters[template.name][parameter.name].value = e.detail.value;
                                      setState({ ...state, parameters: updatedParameters });
                                    }}
                                  />
                                )}

                                {(parameter.type === "enum" || parameter.type === "boolean") && (
                                  <Select
                                    selectedOption={state.parameters[template.name][parameter.name].value}
                                    onChange={({ detail }) => {
                                      const updatedParameters = { ...state.parameters };
                                      updatedParameters[template.name][parameter.name].value = detail.selectedOption;
                                      setState({ ...state, parameters: updatedParameters });
                                    }}
                                    options={parameter.valid_values.split(",").map((value) => ({
                                      label: value,
                                      value: value
                                    }))}
                                    placeholder={`Select value for ${parameter.name}`}
                                  />
                                )}
                              </FormField>
                            );
                          })}
                        </ColumnLayout>
                  </Box>
                      </>
                    )
                ))
              ))}
            </SpaceBetween>
          </Form>
        </form>
      </SpaceBetween>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomerAdd);
